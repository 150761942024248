<!-- Finder particular de Funerarias Sucursales -->

<template>
  <div class="funes_F" v-if="schema">    
     <v-sheet v-show=!modal
             :elevation="4">
      <!-- Cabecera -->
      <div class="cabecera" v-if="Entorno.header.header">       
        <base_Header 
          :Entorno="Entorno.header"
          @onEvent="event_Header">          
        </base_Header>
      </div>

      <!-- Contenido -->
      <div class="contenedor"> 

        <!-- Filtro de Busqueda -->
        <!-- <div @focusin="focusinFiltro()" :style="Entorno.filtro.style" :class="Entorno.filtro.class"> -->
        <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
          <div style="display:flex; align-items:center">
            <div class="columna" style="width:27rem;padding-right:5px">
              
              <v-select
                v-bind="$select"
                v-model="schema.ctrls.zona.value"
                :label="schema.ctrls.zona.label"
                :items="ST_zonas"
                item-value="id"
                item-text="name"
                :disabled="zona >0">
              </v-select>

              <div style="display:flex">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.name.value"
                  :label="schema.ctrls.name.label"
                  @keypress.13="event_search('buscarFuneraria')">
                </v-text-field>

                <v-btn v-bind="$cfg.btn.busca" @click="event_search('buscarFuneraria')">
                  <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
                </v-btn>
              </div>

              <v-checkbox v-if="app==1"
                v-bind="$checkbox"
                v-model="schema.ctrls.bajas.value"
                :label="schema.ctrls.bajas.label">
              </v-checkbox>
            </div>

            <div class="columna">
              <!-- <ctrlfinder finderName="ciac_F" :schema="schema.ctrls.ciac"></ctrlfinder> -->
              <ctrlfinder finderName="atlas_F" :schema="schema.ctrls.loc"></ctrlfinder>

              <div class="inline">
                <v-btn v-bind="$cfg.btn.busca_with_text" @click="event_search('buscarOrigen')">
                    Origen
                  <v-icon right dark>{{ $cfg.btn.busca_with_text.icono }}</v-icon>
                </v-btn>

                <v-btn
                  v-bind="$cfg.btn.busca_with_text"
                  @click="event_search('buscarAsignacion', 'header_asignacion')">                
                    Asignación
                  <v-icon right dark>{{ $cfg.btn.busca_with_text.icono }}</v-icon>
                </v-btn>

                <v-btn
                  v-bind="$cfg.btn.busca_with_text"
                  @click="event_search('buscarPresupuestos', 'header_presupuestos')">                
                    Presupuestos
                  <v-icon right dark>{{ $cfg.btn.busca_with_text.icono }}</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <!-- Grid -->
        <base_Fgrid :padre="stName" :Entorno="Entorno" @onEvent="event_Grid">
          <!-- Slot botonera Top -->
          <template v-slot:top_btns="{ Sitem }">         
            <div style="padding:10px 0 0 10px;">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="top"
                @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </div>
          </template>

          <!-- Slot campos grid menos el de acciones -->
          <!-- <template v-slot:fields_grid="{ Sitem, Sheader }">          
            {{ get_fieldGrid(Sitem, Sheader) }}
          </template> -->

          
          <!-- Slot campo acciones del Grid -->
          <template v-slot:acciones_grid="{ Sitem }">
            <btracombi
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="mrow"
              @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
            </btracombi>
          </template>
        </base_Fgrid>
      </div>
    </v-sheet>

    <!-- Mto  -->      
    <div v-show=modal>           
      <component                 
          :is="component_M"
          :padre="stName"
          :accion="accion"
          :accionRow="accionRow"
          :disparo="disparo"
          @onEvent="event_Mto">
      </component>           
    </div>
  

  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base"); 
  const base_Fgrid = () => plugs.groute("base_Fgrid.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");  
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const funes_M = () => plugs.groute("funes_M.vue", "comp");

  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btracombi, ctrlfinder, funes_M },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },
  

    data() {
      return {  
        schema:null,
        api:'funes_F',
        stName:'stFfunes',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,
     
        headers: {},
        ST_zonas: []        
      };
    },
    
    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno 
        this.Entorno.header.titulo = "Funerarias Sucursales";  

        this.headers = {
          header: [
              { text: "Acciones", value: "acciones", sortable: false, width: "auto" },
            { text: "Funeraria", value: "name", witdh: "10rem" },
            { text: "Origen", value: "origen", witdh: "10rem" },
            { text: "Situ", value: "situ", witdh: "10rem" },
          
          ],

          header_asignacion: [
             { text: "Acciones", value: "acciones", sortable: false, width: "auto" },
            { text: "Funeraria", value: "name", witdh: "10rem" },
            { text: "Origen", value: "origen", witdh: "10rem" },
            { text: "Asignación.", value: "asignacion", witdh: "10rem" },
            { text: "Compañía", value: "ciac", witdh: "10rem" },
            { text: "Situ", value: "situ", witdh: "10rem" }           
          ],

          header_presupuestos: [
              { text: "Ver", value: "acciones", sortable: false, width: "auto" },
            { text: "Compañía", value: "cianame", witdh: "10rem" },
            { text: "Funeraria", value: "name", witdh: "10rem" },
            { text: "Municipio", value: "municipio", witdh: "10rem" },
            { text: "Pft", value: "preferente", witdh: "10rem" },
            { text: "Tipo", value: "tipo", witdh: "10rem" },
            { text: "Descripción", value: "descrip", witdh: "10rem" },
            { text: "Imp fun", value: "pnfsuma", witdh: "10rem" },
            { text: "Importe", value: "imp", witdh: "10rem" },
            { text: "Imp Cia", value: "imp_cia", witdh: "10rem" },
          
          ]

        };

        this.Entorno.grid.headers = this.set_headers();

        // guardo nombre componente de Mto
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'funes_M';

        // obtengo items zonas 
        this.ST_zonas = this.$store.state.datos_iniciales.zonas;
        this.ST_zonas.unshift({ id: "0", name: "Todas" });
      },   


      //
      ini_schemaCtrls() {
        this.schema.ctrls.zona.value= this.zona;
        //cia omision
        this.schema.ctrls.ciac.value='83';
      },


      // Recoge eventos de busqueda
      event_search(nameBtn = "", header) {
        console.log("dev "+this.Entorno.api+" event_search: " + nameBtn + " header:" + header);
        switch (nameBtn) {
          case "buscarOrigen":
          case "buscarAsignacion":
          case "buscarPresupuestos":
            if (!this.schema.ctrls.loc.value) return this.$root.$alert.open("Introduzca un municipio de busqueda", "error");
            break;
          
          // default:
          // break;
        }

        this.buscarExec(nameBtn, header);
      },

      
      // configuración particular campos del grid
      get_fieldGrid(item, header) {
        switch (header.value) {
          case "preferente":
            return item[header.value] === "1" ? "P" : "";

          default:
            return item[header.value];
        }
      }
    }
  }
</script>


